import Vue from 'vue';

const obs = Vue.observable({
    data: [],
    lastUpdated: null,
});

/**
 * Controla si la fecha de última actualización es mayor a 6 horas.
 */
const expiredData = () => {
    const dateNow = new Date().getTime() / 1000;
    const dateTarget = dateNow - ( 4 * 60 * 60 );


    if(!obs.lastUpdated){
        return true;
    }

    if(obs.lastUpdated <= dateTarget){
        return true;
    }

    return false;
}

/**
 * Actualiza la lista de epg
 */
const update = () => {
    const filters =  {
            rango: {
                desde: 0,
                hasta: 1000,
            }}



    return new Promise((resolve) => {
        if(expiredData()){
            telecentro.tplay.core.epg.obtenerGuiaCanales(filters, (res) => {
                // console.log('res en crudo',res);
                const epg = res.map((item, index) => {
                    const channel = {
                        id: index,
                        name: res[index][0].channel.name,
                        lcn: res[index][0].channel.lcn,
                        dvbTriplet: res[index][0].channel.dvbTriplet,
                        logo: res[index][0].channel.logo,
                        logoCanal: res[index][0].channel.logo,
                        programs: [],
                    };
                    item.map((e, eIndex) => {
                        channel.programs.push({
                            idAsset: e.idAsset,
                            title: e.title,
                            poster: e.poster,
                            status: e.status,
                            channel: e.channel,
                            startHourUnixTime: e.startHourUnixTime,
                            endHourUnixTime: e.endHourUnixTime,
                            startHour: e.startHour,

                        });
                    });
                    return channel;
                });
                obs.lastUpdated = new Date().getTime() / 1000;
                resolve(epg);
            }, (err) => {
                //console.log('[ OBS - EPG --> update] no se pudo actualizar la lista epg');
                resolve(null);
            });
        } else {
            resolve(obs.data);
        }
    })
}

/**
* Para no ir a buscar el status al servidor (optimizar tiempos)
* Se puede calcular el status con el start y end time
* Toma como parametro un programa y devuelve un string 'PRESENTE', 'PASADO', 'FUTURO'
*/
const getStatus = (program) => {
    const timeNow = parseInt(new Date().getTime() / 1000);
    if(program.endHourUnixTime < timeNow){
        return 'PASADO';
    } else if(program.startHourUnixTime > timeNow){
        return 'FUTURO';
    } else {
        return 'PRESENTE';
    }
}

/**
 * Recorre y Actualiza el status de cada programa de la epg.
 * Este método debe utilizarse cuando se dispara el evento de cambio de programación
 */
const updateStatus = () => {
    for (let i = 0; i < obs.data.length; i++) {
        const programs = obs.data[i].programs;
        for (let j = 0; j < programs.length; j++) {
            const program = programs[j];
            obs.data[i].programs[j].status = getStatus(program);
        }
    }
}



export { obs, update, updateStatus, getStatus };
